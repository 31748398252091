import React from 'react';
import styled from 'styled-components';
import Transition from '@components/Transition';
import Title from '@components/Title';
import Blurb from '@components/Blurb';
import BlurbImage from '@components/BlurbImage';
import Text from '@components/Text';
import Browser from '@components/Browser';
import zingle1 from '@assets/img/zingle-1.jpg';
import { Helmet } from "react-helmet"

const Zingle = () => (
    <Transition>
        <Helmet>
            <title>Zingle | Austen Payan</title>
            <link rel="canonical" href="https://austen.io/work/zingle" />
        </Helmet>
        <Title>Zingle</Title>
        <Blurb
            left={() => (
                <Text>
                    <ul>
                        <li>Senior Frontend Engineer</li>
                        <li>2016 - 2019</li>
                    </ul>
                </Text>
            )}
            right={() => (
                <>
                    <Text>Zingle provides messaging software for brick & mortar business, helping them connect with their customers wherever and whenever. As Senior Frontend Engineer, I was the stakeholder for Zingle's flagship web application.</Text>
                    <br />
                    <Text>Built with React and Redux, and leveraging modern build tools like Webpack, the Zingle web-app shines among competing products in the customer messaging space.</Text>
                </>
            )}
        />
        <Browser
            src={zingle1}
        />
        <Gallery>
            <GalleryItemContainer>
                <GalleryItem
                    src="https://storage.googleapis.com/portfolio-apay/feed-search.gif"
                />
            </GalleryItemContainer>
            <GalleryItemContainer>
                <GalleryItem
                    src="https://storage.googleapis.com/portfolio-apay/typing-emoji.gif"
                />
            </GalleryItemContainer>
            <GalleryItemContainer>
                <GalleryItem
                    src="https://storage.googleapis.com/portfolio-apay/add-tag.gif"
                />
            </GalleryItemContainer>
        </Gallery>
        <Blurb
            left={() => (
                <Text>Sweating the details.</Text>
            )}
            right={() => (
                <>
                    <Text>Attention to detail is of utmost importance when it comes to designing and building delightful user interfaces. At Zingle, we decided early in the web-app redesign process that thoughtful details and delightful experiences would be an integral part of our product moving forward. My job was to take that directive and build our UI components in a way that would easily support what I like to call “micro-experiences”; small bits of UI that make subtle, almost subconscious impressions in the mind of our users that increase their enjoyment and confidence in our product.</Text>
                    <br />
                    <Text>The most obvious low-hanging fruit in this realm of “micro-experiences” was the display of loading state. We felt it was important that every click or action taken in our product should feel responsive and alive. We did this by adding visual loading indicators wherever necessary and building that functionality into our React components. For example, clicking on a button anywhere in our app will provide a loading indicator if the action requires a request to the server:</Text>
                    <BlurbImage src="https://storage.googleapis.com/portfolio-apay/button-loader.gif" />
                    <Text>Similarly, whenever a user navigates to a part of the app that requires more resources to be fetched from the server, we show a skeleton loader as a rough representation of what the loaded page will look like. This helps improve perceived performance.</Text>
                    <Gallery withMargin>
                        <GalleryItemContainer>
                            <GalleryItem src="https://storage.googleapis.com/portfolio-apay/feed-loading.jpg" />
                        </GalleryItemContainer>
                        <GalleryItemContainer>
                            <GalleryItem src="https://storage.googleapis.com/portfolio-apay/feed-loaded.jpg" />
                        </GalleryItemContainer>
                    </Gallery>
                    <Text>These details, among many others (such as keyboard shortcuts, state persistence in LocalStorage, and smart truncation) leave the user feeling like they are standing on solid ground when using the product. These small micro-experiences build up in the subconcious of the user over time, leading not only to better impressions and more usage, but also stickyness, and ultimately, increased revenue.</Text>
                </>
            )}
            useTopRule={true}
        />
    </Transition>
);

const Gallery = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 -8px;
    ${props => props.withMargin ? `
        margin-top: 40px;
        margin-bottom: 40px;
    ` : null}
`;

const GalleryItemContainer = styled.div`
    position: relative;
    height: 450px;
    border-radius: 4px;
    overflow: hidden;
    margin: 8px;
`;

const GalleryItem = styled.img`
    height: 100%;
    display: block;
`;


export default Zingle;